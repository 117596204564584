/* eslint-disable react/no-array-index-key */
import React from 'react';
import classNames from 'classnames';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, defineMessages } from 'react-intl';
import { withUrlGenerator } from '@folklore/react-container';
// import { useSpeech } from 'react-use';

// import useVoice from '../hooks/useVoice';
import useClearMedia from '../hooks/useClearMedia';

import ArrowIcon from '../icons/Arrow';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/pages/end.scss';

const messages = defineMessages({
    voice: {
        id: 'content.end.voice',
        defaultMessage: 'Merci pour votre participation subjugante!',
    },
    thanks: {
        id: 'content.end.thanks',
        defaultMessage: 'Merci!',
    },
    retry: {
        id: 'content.end.retry',
        defaultMessage: 'Rire à nouveau',
    },
    back: {
        id: 'content.end.back',
        defaultMessage: 'Recommencer',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const defaultProps = {};

const End = ({ intl, urlGenerator }) => {
    useClearMedia();
    return (
        <div className={styles.container}>
            <div className={styles.section} />
            <div className={styles.section}>
                <h1 className={styles.title}>{intl.formatMessage(messages.thanks)}</h1>
            </div>
            <div className={styles.section}>
                <p />
                <Link
                    className={classNames([styles.button, styles.back])}
                    to={urlGenerator.route('home')}
                >
                    <span>
                        <ArrowIcon />
                    </span>
                    <span>{intl.formatMessage(messages.back)}</span>
                </Link>
            </div>
        </div>
    );
};

End.propTypes = propTypes;
End.defaultProps = defaultProps;

const WithUrlGeneratorContainer = withUrlGenerator()(End);

export default React.memo(injectIntl(WithUrlGeneratorContainer));
