import { LOCATION_CHANGE } from 'connected-react-router';
import {
    SET_ERRORS,
    SET_STREAM,
    SET_BLOB_URL,
    SET_BLOB,
    SET_ANALYSIS,
    SET_ANIMATION,
} from '../actions/SiteActions';

const initialState = {
    errors: null,
    statusCode: null,
    messages: null,
    stream: null,
    blobUrl: null,
    blob: null,
    analysis: [],
    animation: false,
};

const SiteReducer = (previousState, action) => {
    let state = previousState || initialState;
    if (typeof state.hydrated === 'undefined' || !state.hydrated) {
        state = {
            ...initialState,
            ...previousState,
            hydrated: true,
        };
    }
    // console.log(state.analysis);
    switch (action.type) {
    case SET_ERRORS:
        return {
            ...state,
            errors: action.payload,
        };
    case SET_STREAM:
        return {
            ...state,
            stream: action.payload,
        };
    case SET_BLOB_URL:
        return {
            ...state,
            blobUrl: action.payload,
        };
    case SET_BLOB:
        return {
            ...state,
            blob: action.payload,
        };
    case SET_ANALYSIS:
        return {
            ...state,
            analysis: [...state.analysis, ...action.payload],
        };
    case SET_ANIMATION:
        return {
            ...state,
            animation: action.payload,
        };
    case LOCATION_CHANGE:
        return typeof state.initialLocationChange === 'undefined'
                || !state.initialLocationChange
            ? {
                ...state,
                initialLocationChange: true,
            }
            : {
                ...state,
                errors: null,
                statusCode: null,
                messages: null,
            };
    default:
        return state;
    }
};

export default SiteReducer;
