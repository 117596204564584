import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { withUrlGenerator } from '@folklore/react-container';

import PageMeta from '../partials/PageMeta';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/pages/process.scss';

const messages = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'Enregistreuse de rires',
    },
    title: {
        id: 'content.process.title',
        defaultMessage: 'Un projet de recherche en intelligence artificielle sur le rire',
    },
    description: {
        id: 'content.process.description',
        defaultMessage:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const WhoPage = ({ intl, urlGenerator }) => (
    <div className={styles.container}>
        <PageMeta title={messages.metaTitle} />
        <div className={styles.info}>
            <h1>{intl.formatMessage(messages.title)}</h1>
            <p>{intl.formatMessage(messages.description)}</p>
            <p>{intl.formatMessage(messages.description)}</p>
            <div className={styles.back}>
                <Link className={styles.button} to={urlGenerator.route('home')}>
                    Retour
                </Link>
            </div>
        </div>
    </div>
);

WhoPage.propTypes = propTypes;

const WithIntlContainer = injectIntl(WhoPage);
const WithUrlGeneratorContainer = withUrlGenerator()(WithIntlContainer);

export default WithUrlGeneratorContainer;
