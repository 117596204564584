/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../styles/icons/ring.scss';

const propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#22B573',
    size: 24,
    className: null,
};

const Accept = ({ color, size, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width={`${size}px`}
        height={`${size}px`}
        viewBox={`0 0 ${size} ${size}`}
        className={classNames([styles.container, { [className]: className !== null }])}
        xmlSpace="preserve"
    >
        <g>
            <polyline
                fill="none"
                stroke={color}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="2,12.5 10.6,22 22,2 "
            />
        </g>
    </svg>
);

Accept.propTypes = propTypes;
Accept.defaultProps = defaultProps;

export default Accept;
