/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { Canvas } from 'react-three-fiber';
import { useSelector } from 'react-redux';
// import { useTween } from 'react-use';

import useAnimation from '../hooks/useAnimation';

// import Wave from './Wave';

import styles from '../../../styles/partials/background.scss';

const propTypes = {};

const defaultProps = {};

const Background = () => {
    const items = useRef([]);
    const animation = useSelector(state => state.site.animation);
    const refresh = useAnimation(
        'inOutSine',
        animation === true || items.current.length > 100 ? 50000 : 0,
        0,
    );
    useEffect(() => {
        if (animation) {
            items.current.push(1);
        } else if (items.current.length > 100) {
            items.current.pop();
        }
    }, [refresh]);

    const height = 0 + Math.floor(200 * (items.current.length / 100));
    const intensity = Math.min(items.current.length / 200, 2);

    return (
        <div className={styles.container}>
            <Canvas
                camera={{ position: [0, 0, 50] }}
                onCreated={({ gl }) => {
                    gl.shadowMap.enabled = true; // eslint-disable-line
                    gl.shadowMap.type = THREE.PCFSoftShadowMap; // eslint-disable-line
                }}
            >
                <mesh receiveShadow>
                    <planeBufferGeometry attach="geometry" args={[1000, 1000]} />
                    <meshPhongMaterial attach="material" color="#000000" />
                </mesh>
                <spotLight color="#fff" />
                <ambientLight intensity={intensity} color="#ffffff" />
                <ambientLight intensity={intensity} color="#ffffff" />
                <spotLight
                    intensity={intensity}
                    position={[30, 30, height]}
                    angle={0.2}
                    penumbra={1}
                    castShadow
                />
                <spotLight
                    intensity={1}
                    position={[30, 30, height]}
                    angle={0.2}
                    penumbra={1}
                    castShadow
                />
            </Canvas>
        </div>
    );
};

Background.propTypes = propTypes;
Background.defaultProps = defaultProps;

export default Background;
