/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { withUrlGenerator } from '@folklore/react-container';
import { Link } from 'react-router-dom';

import RecorderService from '../../lib/recorder/recorder';
// import utils from '../../lib/recorder/utils';

import { MEDIA_RECORDING_STARTED, MEDIA_RECORDING_ENDED } from '../mediaStates';

import PageMeta from '../partials/PageMeta';
import Status from '../partials/Status';
import Recorder from '../partials/Recorder';

import useRecorderState from '../hooks/useRecorderState';
// import useClearMedia from '../hooks/useClearMedia';
// import usePermission from '../hooks/usePermission';

import {
    setBlobUrl as setBlobUrlAction,
    setAnimation as setAnimationAction,
    // setBlob as setBlobAction,
    // setStream as setStreamAction,
} from '../../actions/SiteActions';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/pages/record.scss';

const messages = defineMessages({
    next: {
        id: 'content.record.next',
        defaultMessage: 'Continuer',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    time: PropTypes.number,
    delay: PropTypes.number,
    debug: PropTypes.bool,
};

const defaultProps = {
    time: 10,
    delay: 2,
    debug: false,
};

const RecordPage = ({
    intl, urlGenerator, time, delay, debug,
}) => {
    const blobUrl = useSelector(s => s.site.blobUrl);
    const [recordings, setRecordings] = useState([]);
    const [recording, setRecording] = useState(false);
    const [recorder, setRecorder] = useState(null);
    const [recorderState, count] = useRecorderState({ started: recording, time, delay });

    const dispatch = useDispatch();
    // const setStream = useCallback(rec => dispatch(setStreamAction(rec)), [dispatch]);
    const setBlobUrl = useCallback(media => dispatch(setBlobUrlAction(media)), [dispatch]);
    const setAnimation = useCallback(media => dispatch(setAnimationAction(media)), [dispatch]);

    // console.log(MediaRecorder.isTypeSupported('audio/webm'));

    useEffect(() => {
        setBlobUrl(null);
        const recorderSrvc = new RecorderService();
        setRecorder(recorderSrvc);
    }, []);

    useEffect(() => {
        if (recorderState === MEDIA_RECORDING_ENDED) {
            recorder.stopRecording();
            setRecording(false);
            setAnimation(false);
        }
        if (recorderState === MEDIA_RECORDING_STARTED) {
            setAnimation(true);
        }
    }, [recorderState]);

    const onClick = useCallback(() => {
        if (recording) {
            // recorder.stopRecording();
            // setRecording(false);
        } else {
            recorder.em.addEventListener('recording', (evt) => {
                // console.log('event', evt); // eslint-disable-line
                setRecordings(s => [...s, evt.detail.recording]);
                setBlobUrl(evt.detail.recording.blobUrl);
            });
            recorder
                .startRecording()
                .then(() => {
                    setRecording(true);
                })
                .catch((error) => {
                    console.log(`Exception while start recording: ${error}`); // eslint-disable-line
                    setRecording(false);
                });
        }
    }, [recorder, recording]);

    return (
        <div className={styles.container}>
            <PageMeta title={messages.metaTitle} />
            <div className={styles.section}>
                <Status status={recorderState} count={count + 1} />
            </div>
            <div className={styles.section}>
                <Recorder
                    started={recorderState >= MEDIA_RECORDING_STARTED}
                    time={recorderState === MEDIA_RECORDING_STARTED ? count : 0}
                    onClick={onClick}
                />
            </div>
            <div className={styles.section}>
                {recorder !== null && recorder.error !== null ? <p>{recorder.error}</p> : null}
                {recorderState === MEDIA_RECORDING_ENDED && blobUrl ? (
                    <Link to={urlGenerator.route('playback')} className={styles.button}>
                        {intl.formatMessage(messages.next)}
                    </Link>
                ) : null}
                {recordings.length > 0 && debug
                    ? recordings.map((r, i) => (
                        <audio key={`audio-${i + 1}`} controls track="fake" src={r.blobUrl} />
                    ))
                    : null}
            </div>
        </div>
    );
};

RecordPage.propTypes = propTypes;
RecordPage.defaultProps = defaultProps;

const WithIntlContainer = injectIntl(RecordPage);
const WithUrlGeneratorContainer = withUrlGenerator()(WithIntlContainer);

export default WithUrlGeneratorContainer;
