import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { withUrlGenerator } from '@folklore/react-container';

import Ring from '../icons/Ring';
import PageMeta from '../partials/PageMeta';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/pages/home.scss';

const messages = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'Enregistreuse de rires',
    },
    voice: {
        id: 'content.home.voice',
        defaultMessage:
            "Bonjour je suis une enregistreuse de rires. Ha haha... haha haha... haha. Vous pouvez m'aider à m'améliorer un peu?",
    },
    title: {
        id: 'content.home.title',
        defaultMessage:
            'Enregistrez votre rire pour l’avancement de l’art, des technologies et des rires en canne',
    },
    description: {
        id: 'content.home.description',
        defaultMessage:
            'Votre rire sera ajouté à une banque de rires anonymes pour être interprété par une intelligence artificielle supérieure.',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const HomePage = ({ intl, urlGenerator }) => (
    <div className={styles.container}>
        <PageMeta title={messages.metaTitle} />
        <div className={styles.info}>
            <h1>{intl.formatMessage(messages.title)}</h1>
            <p>{intl.formatMessage(messages.description)}</p>
        </div>
        <div className={styles.record}>
            <Link className={styles.link} to={urlGenerator.route('record')}>
                <Ring outerColor="transparent" outerSizeDivider={3} percent="0" />
            </Link>
        </div>
    </div>
);
HomePage.propTypes = propTypes;

const WithIntlContainer = injectIntl(HomePage);
const WithUrlGeneratorContainer = withUrlGenerator()(WithIntlContainer);

export default WithUrlGeneratorContainer;
