/**
 * Constants
 */
export const SET_ERRORS = 'site@SET_ERRORS';
export const SET_STREAM = 'site@SET_STREAM';
export const SET_BLOB_URL = 'site@SET_BLOB_URL';
export const SET_BLOB = 'site@SET_BLOB';
export const SET_ANALYSIS = 'site@SET_ANALYSIS';
export const SET_ANIMATION = 'site@SET_ANIMATION';

/**
 * Actions creator
 */
export const setErrors = payload => ({
    type: SET_ERRORS,
    payload,
});

export const resetErrors = () => ({
    type: SET_ERRORS,
    payload: null,
});

export const setStream = payload => ({
    type: SET_STREAM,
    payload,
});

export const setBlobUrl = payload => ({
    type: SET_BLOB_URL,
    payload,
});

export const setBlob = payload => ({
    type: SET_BLOB,
    payload,
});

export const setAnalysis = payload => ({
    type: SET_ANALYSIS,
    payload,
});

export const setAnimation = payload => ({
    type: SET_ANIMATION,
    payload,
});
