/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../styles/icons/ring.scss';

const propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#22b573',
    size: 40.5,
    className: null,
};

const Play = ({ color, size, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width={`${size}px`}
        height={`${size}px`}
        viewBox={`0 0 ${size} ${size}`}
        className={classNames([styles.container, { [className]: className !== null }])}
        xmlSpace="preserve"
    >
        <g>
            <polygon
                fill="none"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="39,20.2 1.5,39 1.5,1.5 "
            />
        </g>
    </svg>
);

Play.propTypes = propTypes;
Play.defaultProps = defaultProps;

export default Play;
