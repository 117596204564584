import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import moment from 'moment';
// import Bowser from 'bowser';

import { defineMessages, injectIntl } from 'react-intl';
import { withUrlGenerator } from '@folklore/react-container';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { uploadFile, postJSON } from '@folklore/fetch';
// import { dataUriToBlob } from '@folklore/user-media';

import PageMeta from '../partials/PageMeta';
import Player from '../partials/Player';
import Button from '../buttons/Button';
import AcceptIcon from '../icons/Accept';
import CancelIcon from '../icons/Cancel';

// import useVoice from '../hooks/useVoice';

import {
    setBlobUrl as setBlobUrlAction,
    setBlob as setBlobAction,
} from '../../actions/SiteActions';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/pages/playback.scss';

const messages = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'Enregistreuse de rires',
    },
    listen: {
        id: 'content.playback.listen',
        defaultMessage: 'Écoutez le résultat',
    },
    save: {
        id: 'content.playback.save',
        defaultMessage: 'Soumettre votre rire',
    },
    sending: {
        id: 'content.playback.sending',
        defaultMessage: 'Envoi du rire...',
    },
    cancel: {
        id: 'content.playback.cancel',
        defaultMessage: 'Rire à nouveau',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    history: AppPropTypes.history.isRequired,
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const PlaybackPage = ({ intl, history, urlGenerator }) => {
    const [loading, setLoading] = useState(false);
    const blobUrl = useSelector(s => s.site.blobUrl);
    const dispatch = useDispatch();
    const setBlobUrl = useCallback(url => dispatch(setBlobUrlAction(url)), [dispatch]);
    const setBlob = useCallback(b => dispatch(setBlobAction(b)), [dispatch]);

    const clearData = useCallback(() => {
        setBlobUrl(null);
        setBlob(null);
    });

    const onCancel = useCallback(() => {
        clearData();
        history.push(urlGenerator.route('record'));
    });

    const onSave = useCallback(() => {
        if (loading || !blobUrl) return;
        setLoading(true);
        fetch(blobUrl).then((r) => {
            r.blob()
                .then((blob) => {
                    uploadFile('/mediatheque/upload/audio', blob, {
                        processData: false,
                        contentType: false,
                    })
                        .then((data) => {
                            postJSON('/api/save', {
                                data: {
                                    name: `Son ${moment()
                                        .locale(intl.locale)
                                        .format('LLL')}`,
                                    source: 'Website',
                                    audio: data,
                                },
                            })
                                .then(() => {
                                    clearData();
                                    setLoading(false);
                                    history.push(urlGenerator.route('end'));
                                })
                                .catch(() => {
                                    setLoading(false);
                                });
                        })
                        .catch(() => {
                            setLoading(false);
                        });
                })
                .catch(() => {
                    setLoading(false);
                });
        });
    }, [loading, blobUrl]);

    return (
        <div className={styles.container}>
            <PageMeta title={messages.metaTitle} />
            <div className={styles.section}>
                <h1>{intl.formatMessage(messages.listen)}</h1>
            </div>
            <div className={styles.section}>
                <Player />
            </div>
            <div className={styles.section}>
                <Button
                    className={classNames([styles.button, styles.cancel])}
                    innerClassName={classNames([styles.inner, styles.cancel])}
                    onClick={onCancel}
                    disabled={loading}
                >
                    <span>
                        <CancelIcon />
                    </span>
                    <span>{intl.formatMessage(messages.cancel)}</span>
                </Button>
                {blobUrl ? (
                    <Button
                        className={classNames([styles.button, styles.save])}
                        innerClassName={classNames([styles.inner, styles.save])}
                        label={
                            loading
                                ? intl.formatMessage(messages.sending)
                                : intl.formatMessage(messages.save)
                        }
                        icon={<AcceptIcon />}
                        onClick={onSave}
                        disabled={loading}
                    />
                ) : null}
            </div>
        </div>
    );
};

PlaybackPage.propTypes = propTypes;

const WithIntlContainer = injectIntl(PlaybackPage);
const WithUrlGeneratorContainer = withUrlGenerator()(WithIntlContainer);
const WithRouter = withRouter(WithUrlGeneratorContainer);

export default WithRouter;
