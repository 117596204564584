export default function () {
    // Parts copied from https://github.com/chris-rudmin/Recorderjs
    const BYTES_PER_SAMPLE = 2;
    let recorded = [];

    function encode(buffer) {
        const { length } = buffer;
        const data = new Uint8Array(length * BYTES_PER_SAMPLE);
        for (let i = 0; i < length; i += 1) {
            const index = i * BYTES_PER_SAMPLE;
            let sample = buffer[i];
            if (sample > 1) {
                sample = 1;
            } else if (sample < -1) {
                sample = -1;
            }
            sample *= 32768;
            data[index] = sample;
            data[index + 1] = sample >> 8; // eslint-disable-line
        }
        recorded.push(data);
    }

    function dump(sampleRate) {
        const bufferLength = recorded.length ? recorded[0].length : 0;
        const length = recorded.length * bufferLength;
        const wav = new Uint8Array(44 + length);

        const view = new DataView(wav.buffer);

        // RIFF identifier 'RIFF'
        view.setUint32(0, 1380533830, false);
        // file length minus RIFF identifier length and file description length
        view.setUint32(4, 36 + length, true);
        // RIFF type 'WAVE'
        view.setUint32(8, 1463899717, false);
        // format chunk identifier 'fmt '
        view.setUint32(12, 1718449184, false);
        // format chunk length
        view.setUint32(16, 16, true);
        // sample format (raw)
        view.setUint16(20, 1, true);
        // channel count
        view.setUint16(22, 1, true);
        // sample rate
        view.setUint32(24, sampleRate, true);
        // byte rate (sample rate * block align)
        view.setUint32(28, sampleRate * BYTES_PER_SAMPLE, true);
        // block align (channel count * bytes per sample)
        view.setUint16(32, BYTES_PER_SAMPLE, true);
        // bits per sample
        view.setUint16(34, 8 * BYTES_PER_SAMPLE, true);
        // data chunk identifier 'data'
        view.setUint32(36, 1684108385, false);
        // data chunk length
        view.setUint32(40, length, true);

        for (let i = 0; i < recorded.length; i += 1) {
            wav.set(recorded[i], i * bufferLength + 44);
        }

        recorded = [];
        const msg = [wav.buffer];
        postMessage(msg, [msg[0]]);
    }

    // eslint-disable-next-line
    self.onmessage = function(e) {
        if (e.data[0] === 'encode') {
            encode(e.data[1]);
        } else if (e.data[0] === 'dump') {
            dump(e.data[1]);
        } else if (e.data[0] === 'close') {
            self.close(); // eslint-disable-line
        }
    };
}
