/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSelector } from 'react-redux';
import { useAudio } from 'react-use';

import PlayIcon from '../icons/Play';

import styles from '../../../styles/partials/player.scss';

const propTypes = {};

const defaultProps = {};

const Player = () => {
    const blobUrl = useSelector(s => s.site.blobUrl);
    const [audio, state, controls] = useAudio({
        src: blobUrl,
    });
    // eslint-disable-next-line
    const color = blobUrl !== null ? (state.isPlaying ? '#22B573' : '#CCCCCC') : '#000000';
    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <button
                    className={styles.button}
                    type="button"
                    onClick={() => {
                        if (state.duration > 0) {
                            if (state.isPlaying) {
                                controls.pause();
                            } else {
                                controls.play();
                            }
                        }
                    }}
                >
                    <PlayIcon color={color} />
                </button>
                <div>{audio}</div>
            </div>
        </div>
    );
};

Player.propTypes = propTypes;
Player.defaultProps = defaultProps;

export default React.memo(Player);
