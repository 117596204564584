import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
    setBlobUrl as setBlobUrlAction,
    setBlob as setBlobAction,
    setStream as setStreamAction,
} from '../../actions/SiteActions';

export default () => {
    const dispatch = useDispatch();
    const setStream = useCallback(recorder => dispatch(setStreamAction(recorder)), [dispatch]);
    const setBlobUrl = useCallback(media => dispatch(setBlobUrlAction(media)), [dispatch]);
    const setBlob = useCallback(blob => dispatch(setBlobAction(blob)), [dispatch]);

    useEffect(() => {
        setStream(null);
        setBlobUrl(null);
        setBlob(null);
    }, []);
};
