/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Ring from '../icons/Ring';

import useAnimation from '../hooks/useAnimation';

import styles from '../../../styles/partials/recorder.scss';

const propTypes = {
    started: PropTypes.bool.isRequired,
    time: PropTypes.number,
    onClick: PropTypes.func.isRequired,
};

const defaultProps = {
    time: 0,
};

const Recorder = ({ started, time, onClick }) => {
    const color = started ? '#FFF' : '#EEE';
    const refresh = useAnimation('inOutSine', 2000, 300);
    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <button
                    className={styles.button}
                    type="button"
                    disabled={started}
                    onClick={onClick}
                >
                    <Ring
                        outerColor={color}
                        outerStroke={color}
                        outerSizeDivider={5 - 3 * refresh}
                        percent={started ? 100 : 0}
                        animationTime={time}
                    />
                </button>
            </div>
        </div>
    );
};

Recorder.propTypes = propTypes;
Recorder.defaultProps = defaultProps;

export default React.memo(injectIntl(Recorder));
