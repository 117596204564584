/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../styles/icons/ring.scss';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#990000',
    className: null,
};

const Arrow = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="21.8px"
        height="20px"
        viewBox="0 0 21.8 20"
        className={classNames([styles.container, { [className]: className !== null }])}
        xmlSpace="preserve"
    >
        <g>
            <path fill={color} d="M0,10L10,0h2.8l-9,9h18v2h-18l9,9H10L0,10z" />
        </g>
    </svg>
);

Arrow.propTypes = propTypes;
Arrow.defaultProps = defaultProps;

export default Arrow;
